<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel">
        <div class="panel-heading bg-dark text-light">
          <h3 class="panel-title bold">List Pendukung</h3>
        </div>
        <div class="panel-body p-5">
          <div class="row mb-2 mt-2" v-if="userLevel == 'admin'">
            <div class="col-6 col-md-3">
              <select
                v-model="filter.kabupaten"
                v-on:change="fetchKecamatan()"
                class="form-control"
              >
                <option value="">Pilih Kabupaten/Kota</option>
                <option
                  v-for="kabupaten in listKabupaten"
                  :value="kabupaten.id_kab"
                  :key="kabupaten.id_kab"
                >
                  {{ kabupaten.nama_kabupaten }}
                </option>
              </select>
            </div>
            <div class="col-6 col-md-3">
              <select
                v-model="filter.kecamatan"
                v-on:change="fetchKelurahan()"
                class="form-control"
              >
                <option value="">Pilih Kecamatan</option>
                <option
                  v-for="kecamatan in listKecamatan"
                  :value="kecamatan.id_kec"
                  :key="kecamatan.id_kec"
                >
                  {{ kecamatan.nama_kecamatan }}
                </option>
              </select>
            </div>
            <div class="col-6 col-md-3">
              <select
                v-model="filter.kelurahan"
                class="form-control"
                v-on:change="fetchData()"
              >
                <option value="">Pilih Kelurahan/Desa</option>
                <option
                  v-for="kelurahan in listKelurahan"
                  :value="kelurahan.id_kel"
                  :key="kelurahan.id_kel"
                >
                  {{ kelurahan.nama_kelurahan }}
                </option>
              </select>
            </div>
          </div>
          <vue-good-table
            mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            @on-search="onSearch"
            :totalRows="totalRecords"
            :isLoading.sync="isLoading"
            :search-options="{
              enabled: true,
              trigger: 'keyup',
              placeholder: 'Masukkan Nama',
            }"
            :pagination-options="{
              enabled: true,
              perPageDropdownEnabled: true,
              perPageDropdown: [10, 20, 30, 40, 50, 100],
              dropdownAllowAll: false,
              position: 'bottom',
              rowsPerPageLabel: '',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: '',
            }"
            :rows="rows"
            :columns="columns"
          >
            <div slot="emptystate" class="text-center">
              {{ tableInfo }}
            </div>
            <div slot="table-actions">
              <div style="display: flex">
                <button
                  v-if="filterReset"
                  type="button"
                  v-on:click="resetFilter()"
                  class="btn btn-danger btn-outline-danger mr-2"
                >
                  <i class="fa fa-times"></i>
                </button>
                <button
                  type="button"
                  v-on:click="fetchData()"
                  class="btn btn-white mr-2"
                >
                  <i v-if="isLoading" class="fa fa-sync fa-spin"></i>
                  <i v-else class="fa fa-sync"></i>
                </button>
                <router-link
                  to="/data-pendukung/manual"
                  class="btn btn-primary"
                >
                  <i class="fa fa-plus d-md-none"></i>
                  <span class="d-none d-md-block">Tambah Pendukung</span>
                </router-link>
                <!-- <button
                  type="button"
                  @click="showModal"
                  class="btn btn-primary mr-2"
                >
                  <i class="fa fa-plus d-md-none"></i>
                  <span class="d-none d-md-block">Tambah Data</span>
                </button> -->
              </div>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'ktp'">
                <i
                  v-if="props.row.ktp_pendukung"
                  class="fa fa-check text-success"
                ></i>
                <i v-else class="fa fa-times text-danger"></i>
              </span>
              <span v-if="props.column.field == 'action'">
                <div>
                  <b-dropdown
                    v-if="userLevel != 'relawan'"
                    variant="none"
                    :no-caret="true"
                    toggle-class="btn btn-circle btn-icon btn-primary"
                  >
                    <template slot="button-content">
                      <i class="fa fa-ellipsis-h f-s-14"></i>
                    </template>
                    <!-- <b-dropdown-item
                      v-on:click="setSaksi(props.row.id_pendukung)"
                      >Jadikan Saksi</b-dropdown-item
                    > -->
                    <b-dropdown-item
                      v-on:click="setRelawan(props.row.id_pendukung)"
                      >Jadikan Relawan</b-dropdown-item
                    >
                  </b-dropdown>
                  <router-link
                    :to="'/data-pendukung/detail/' + props.row.id_pendukung"
                    class="btn btn-circle btn-icon btn-success m-l-5 m-r-5"
                  >
                    <i class="fa fa-search"></i>
                  </router-link>
                  <router-link
                    :to="'/data-pendukung/update/' + props.row.id_pendukung"
                    class="btn btn-circle btn-icon btn-warning m-r-5"
                  >
                    <i class="fa fa-pencil-alt"></i>
                  </router-link>
                  <button
                    v-if="
                      userID == props.row.created_by || userLevel == 'admin'
                    "
                    class="btn btn-circle btn-icon btn-danger"
                    v-on:click="confirmDelete(props.row.id_pendukung)"
                  >
                    <i class="fa fa-trash-alt"></i>
                  </button>
                </div>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
    <!-- modal -->
    <b-modal v-model="modalVisible" @hide="beforeModalClose">
      <template #modal-header>
        <h5 class="modal-title">Input Pendukung</h5>
        <button
          type="button"
          @click="closeModal"
          class="btn btn-danger btn-sm btn-icon btn-circle"
          data-dismiss="modal"
          aria-hidden="true"
        >
          ×
        </button>
      </template>
      <div>
        <div class="form-group">
          <label for="kabupaten">Kabupaten/Kota</label>
          <select
            v-model="form.kabupaten"
            name="kabupaten"
            id="kabupaten"
            v-on:change="fetchKecamatan($event)"
            class="form-control"
            :class="{
              'is-invalid': formValidate.kabupaten,
            }"
          >
            <option value="">Pilih</option>
            <option
              v-for="kabupaten in listKabupaten"
              :value="kabupaten.nama_kabupaten"
              :key="kabupaten.id_kab"
              :data-key="kabupaten.id_kab"
            >
              {{ kabupaten.nama_kabupaten }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="kecamatan">Kecamatan</label>
          <select
            v-model="form.kecamatan"
            name="kecamatan"
            id="kecamatan"
            v-on:change="fetchKelurahan($event)"
            class="form-control"
            :class="{
              'is-invalid': formValidate.kecamatan,
            }"
          >
            <option value="">Pilih</option>
            <option
              v-for="kecamatan in listKecamatan"
              :value="kecamatan.nama_kecamatan"
              :key="kecamatan.id_kec"
              :data-key="kecamatan.id_kec"
            >
              {{ kecamatan.nama_kecamatan }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="kelurahan">Desa</label>
          <select
            v-model="form.kelurahan"
            name="kelurahan"
            id="kelurahan"
            class="form-control"
            :class="{
              'is-invalid': formValidate.kelurahan,
            }"
          >
            <option value="">Pilih</option>
            <option
              v-for="kelurahan in listKelurahan"
              :value="kelurahan.nama_kelurahan"
              :key="kelurahan.nama_kelurahan"
            >
              {{ kelurahan.nama_kelurahan }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="nama">Nama</label>
          <input
            v-model="form.nama"
            type="text"
            name="nama"
            id="nama"
            class="form-control"
            :class="{
              'is-invalid': formValidate.nama,
            }"
            placeholder="Masukkan nama sesuai yang tertera di KTP"
          />
        </div>
        <div v-if="statusDPT == '0'">
          <div class="alert alert-warning fade show">
            <span style="text-transform: uppercase" class="text-center"
              ><b>Data Tidak Ada Di DPT, Silahkan Input Secara Manual</b></span
            >
            <button
              class="btn btn-block btn-warning btn-sm mt-2"
              @click="manualDPT"
              variant="primary"
            >
              INPUT DATA MANUAL
            </button>
          </div>
        </div>
        <div v-if="statusDPT == '1'">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th class="text-center">Nama</th>
                <th class="text-center">Desa</th>
                <th class="text-center">TPS</th>
                <th class="text-center">Umur</th>
                <th class="text-center"><i class="fa fa-cogs"></i></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="dpt in listDPT" :key="dpt.id_dpt">
                <td>{{ dpt.nama }}</td>
                <td>{{ dpt.kel }}</td>
                <td class="text-center">{{ dpt.tps }}</td>
                <td class="text-center">{{ dpt.usia }}</td>
                <td class="text-center" style="width: 50px">
                  <button
                    v-on:click="setPendukung(dpt.id_dpt)"
                    class="btn btn-info btn-sm"
                  >
                    Pilih
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <template #modal-footer>
        <button
          class="btn btn-block btn-info"
          @click="fetchDPT"
          variant="primary"
        >
          <i v-if="loadDPT" class="fas fa-circle-notch fa-spin"></i>
          Cek DPT
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../plugins/sipData";
import moment from "moment";

export default {
  mixins: [sipData],
  data() {
    return {
      columns: [
        {
          label: "NIK",
          field: "nik_pendukung",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "KTP",
          field: "ktp",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Nama",
          field: "nama_pendukung",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-nowrap text-uppercase",
        },
        {
          label: "Tanggal Lahir",
          field: "tgl_lahir_pendukung",
          sortable: false,
          formatFn: this.formatDateTime,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap text-uppercase",
        },
        {
          label: "Umur",
          field: "usia_pendukung",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap text-uppercase",
        },
        {
          label: "L/P",
          field: "jenkel_pendukung",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-uppercase text-nowrap",
        },
        {
          label: "Kabupaten/Kota",
          field: "nama_kab",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-uppercase text-nowrap",
        },
        {
          label: "Kecamatan",
          field: "nama_kec",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-uppercase text-nowrap",
        },
        {
          label: "Desa/Kelurahan",
          field: "nama_kel",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-uppercase text-nowrap",
        },
        {
          label: "TPS",
          field: "tps_pendukung",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-uppercase text-nowrap",
        },
        // {
        //   label: "Status DPT",
        //   field: "status",
        //   sortable: false,
        //   thClass: "text-center text-nowrap",
        //   tdClass: "text-center text-uppercase text-nowrap",
        // },
        {
          label: "Aksi",
          field: "action",
          sortable: false,
          width: "100px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: null,
      tableInfo: "Loading data",
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "",
          },
        ],
        page: 1,
        perPage: 10,
      },
      form: {
        nama: "",
        kabupaten: "",
        kecamatan: "",
        kelurahan: "",
      },
      filter: {
        kabupaten: "",
        kecamatan: "",
        kelurahan: "",
      },
      searchTerm: "",
      timeoutSearch: null,
      modalVisible: false,
      conditionMet: false,
      formValidate: [],
      listKabupaten: [],
      listKecamatan: [],
      listKelurahan: [],
      listDesa: [],
      listDPT: [],
      statusDPT: "",
      loadDPT: false,
      userLevel: "",
      filterReset: false,
    };
  },
  created() {
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  mounted() {
    const filterData = JSON.parse(localStorage.getItem("filterData"));
    if (filterData) {
      this.filterReset = true;
      this.searchTerm = filterData.search;
      this.serverParams.page = filterData.page;
      this.serverParams.perPage = filterData.limit;
      this.filter.kecamatan = filterData.kecamatan;
      this.filter.kelurahan = filterData.kelurahan;
    }
    this.fetchData();
    this.fetchKabupaten();
  },
  methods: {
    onSearch(params) {
      clearTimeout(this.timeoutSearch);
      this.timeoutSearch = setTimeout(() => {
        this.searchTerm = params.searchTerm;
        this.fetchData();
      }, 500);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.fetchData();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.fetchData();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params.sortType,
            field: this.columns[params.columnIndex].field,
          },
        ],
      });
      this.fetchData();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.fetchData();
    },

    fetchData() {
      this.isLoading = true;
      this.setFiltered();
      axios
        .get("/v1/pendukung/", {
          headers: {
            token: this.userToken,
          },
          params: {
            search: this.searchTerm,
            page: this.serverParams.page,
            limit: this.serverParams.perPage,
            kabupaten: this.filter.kabupaten,
            kecamatan: this.filter.kecamatan,
            kelurahan: this.filter.kelurahan,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.totalRecords = response.data.total;
          if (this.totalRecords <= 0) {
            this.tableInfo = "Empty data";
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    fetchKabupaten() {
      axios
        .get("/v1/daerah/kabupaten/73", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.listKabupaten = response.data.data;
          this.fetchData();
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    fetchKecamatan() {
      if (this.filter.kabupaten) {
        this.listKecamatan = [];
        axios
          .get("/v1/daerah/kecamatan/" + this.filter.kabupaten, {
            headers: {
              token: this.userToken,
            },
          })
          .then((response) => {
            this.listKecamatan = response.data.data;
            this.fetchData();
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      } else {
        this.listKecamatan = [];
      }
    },

    fetchKelurahan() {
      if (this.filter.kecamatan) {
        this.listKelurahan = [];
        axios
          .get("/v1/daerah/kelurahan/" + this.filter.kecamatan, {
            headers: {
              token: this.userToken,
            },
          })
          .then((response) => {
            this.listKelurahan = response.data.data;
            this.fetchData();
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      } else {
        this.listKelurahan = [];
      }
    },

    fetchDPT() {
      this.loadDPT = true;

      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }

      axios
        .post("/v1/dpt/cek", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loadDPT = false;
            this.formValidate = response.data.message;
          } else {
            if (response.data.status == true) {
              this.listDPT = response.data.data;
              this.statusDPT = "1";
            } else {
              this.statusDPT = "0";
            }
            this.loadDPT = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/v1/pendukung/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData();
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    formatDateTime(dateTime) {
      return moment(dateTime).format("D MMMM YYYY");
    },
    showModal() {
      this.modalVisible = true;
      this.conditionMet = false;
    },
    closeModal() {
      this.modalVisible = false;
      this.conditionMet = true;
    },
    beforeModalClose(evt) {
      if (!this.conditionMet) {
        evt.preventDefault();
      }
    },
    manualDPT() {
      const dptManual = {
        kecamatan: this.form.kecamatan,
        kelurahan: this.form.kelurahan,
        nama: this.form.nama,
      };

      localStorage.setItem("dptManual", JSON.stringify(dptManual));
      this.$router.push("data-pendukung/manual/");
    },
    setPendukung(params) {
      this.$router.push("data-pendukung/dpt/" + params);
      // window.alert(params);
    },
    setSaksi(params) {
      this.$router.push("data-saksi/set/" + params);
      // window.alert(params);
    },
    setRelawan(params) {
      this.$router.push("data-relawan/set/" + params);
      // window.alert(params);
    },
    setFiltered() {
      var filterData = JSON.parse(localStorage.getItem("filterData"));
      filterData = {
        search: this.searchTerm,
        page: this.serverParams.page,
        limit: this.serverParams.perPage,
        kabupaten: this.filter.kabupaten,
        kecamatan: this.filter.kecamatan,
        kelurahan: this.filter.kelurahan,
      };
      localStorage.setItem("filterData", JSON.stringify(filterData));
      if (
        this.searchTerm ||
        this.serverParams.page > 1 ||
        this.serverParams.perPage != 10 ||
        this.filter.kabupaten ||
        this.filter.kecamatan ||
        this.filter.kelurahan
      ) {
        this.filterReset = true;
      } else {
        this.filterReset = false;
      }
    },
    resetFilter() {
      localStorage.removeItem("filterData");
      this.filterReset = false;
      this.searchTerm = "";
      this.serverParams.page = 1;
      this.serverParams.perPage = 10;
      this.filter.kabupaten = "";
      this.filter.kecamatan = "";
      this.filter.kelurahan = "";
      this.fetchData();
    },
  },
};
</script>